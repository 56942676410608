import eventBus from "./context/eventBus";
import intersectionObserverProvider from "./context/intersectionObserverProvider";
import translateParamsToObject from "./utils/translateParamsToObject";

let CONTEXT = null;

export default function getContext() {
    if (CONTEXT == null) {
        CONTEXT = {
            intersectionObserverProvider,
            eventBus,
            settings: window.REBELMOUSE_BOOTSTRAP_DATA,
            pathParams: translateParamsToObject(window.location.search),
        };
    }

    return CONTEXT;
}
